<template>

  <section id="perfil-list">

    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          Aquí podrás crear perfiles para postear en nombre de áreas, gerencias o de iniciativas corporativas. Deberás ingresar un nombre, seleccionar un color y cargar una imagen de perfil.

        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <button
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50" 
          @click="open_add_publi = true"
          data-bs-toggle="modal" 
          data-bs-target="#modal-form-perfil"
          >
          <font-awesome-icon icon="plus" class="pe-2"/>
          Crear perfil
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="table-container">
          <table class="table table-custom table-borderless table-custom-responsive mb-0">
            <thead>
              <tr>
                <th scope="col">Perfil</th>
                <th scope="col" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(doc, k) in profile_list"
                :key="k"
                :class="doc.id_estado_fk == '1' ? '' : 'disabled'"
                >
                <td class="th">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <img
                        :src="doc.imagen_perfil"
                        class="table-custom-user-img mr-2"
                        :key="k+img_keys"/>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h6 class="mb-0">{{ doc.nombre_perfil }}</h6>
                      Etiqueta
                      <span :style="`color:${doc.color}`">
                        <font-awesome-icon icon="circle-check"/>
                      </span> 
                    </div>
                  </div>
                </td>
                <td class="th th-accion text-center w-150">
                  <div 
                    class="dropdown dropdown-custom d-flex justify-content-center" 
                    @click="openOption(doc.id_perfil)">
                    <button 
                      class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                      type="button" 
                      data-bs-toggle="dropdown">
                      <font-awesome-icon icon="ellipsis-vertical"/>
                    </button>
                    <ul 
                      class="dropdown-menu"
                      v-click-outside="outOption">
                      <li>
                        <a 
                          @click="openEdit(doc)"
                          class="dropdown-item" 
                          data-bs-toggle="modal" 
                          data-bs-target="#modal-form-perfil"
                          href="javascript:">
                          <font-awesome-icon class="pe-2 color-main" icon="pen"/>
                          Editar
                        </a>
                      </li>
                      <li>
                        <a 
                          @click="openQuestionDelete(doc)"
                          class="dropdown-item" 
                          href="javascript:">
                          <font-awesome-icon class="pe-2 color-main" icon="trash-alt"/>
                          Eliminar
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div 
      class="modal modal-custom fade" 
      id="modal-form-perfil" 
      data-bs-backdrop="static" 
      data-bs-keyboard="false"
      tabindex="-1" 
    >
      <div class="modal-dialog modal-lg">
        <ModalWallPerfil 
          :obj="item_selected" 
          v-if="open_add_publi" 
          @back="back"
        />
      </div>
    </div>

    <Question
        v-if="open_question_modal"
        :msg="question_modal_msg"
        :hideCancel="false"
        @cancel="cancelQuestion"
        @accept="acceptQuestion"
      />
      <Status
        v-if="open_modal_status"
        :msg="modal_status_msg"
        :status="modal_status"
        @close="closeStatus"
      />
      <Spinner v-if="show_spinner"/>

  </section>

</template>

<script>
import Question from "../Modales/Question.vue";
import Status from "../Modales/Status.vue";
import { mapActions, mapState } from "vuex";
import Spinner from "../Spinner.vue";
import ModalWallPerfil from "./ModalWallPerfil.vue";

export default {
  components: { Question, Status, Spinner, ModalWallPerfil },
  data() {
    return {
      open_modal_preview: false,
      open_question_modal: false,
      question_modal_msg: "",
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      show_spinner: false,
      profile_to_delete: null,
      open_add_publi: false,
      item_selected: null,
      id_usuario: this.$ls.get("user").id_usuario,
      option: "",
      prev_wall: {},
      img_keys:1,
    };
  },
  mounted() {
    this.getAllProfiles();
  },
  computed: {
    ...mapState("wallModule", ["profile_list"]),
  },
  watch: {
  },
  methods: {
    ...mapActions("wallModule", ["getProfiles", "removeProfile",]),
    back(){
      this.open_add_publi = false;
      this.item_selected = null;
      this.img_keys = this.img_keys+1;
    },
    async deleteProfile(){
      this.show_spinner = true;
      let data_delete = {
        id_perfil: this.profile_to_delete,
        id_empresa: this.id_empresa,
        id_usuario: this.id_usuario
      }
      this.removeProfile(data_delete).then(res =>{
        if(res != null){
          this.$toast.open({
            message:`El perfil ha sido eliminado correctamente.`,
            type:'success',
            duration:6000,
            position:'top-right'
          })
          this.profile_to_delete = null;
        }else{
          this.$toast.open({
            message:`Ocurrió un error al eliminar el perfil, intentalo nuevamente.`,
            type:'error',
            duration:0,
            position:'top-right'
          })
        }
        console.log('respuesta eliminar desde el store', res);
      })
      this.open_question_modal = false;
      this.show_spinner = false;
    },
    async getAllProfiles(){
      let data_profiles = {
        id_empresa: this.id_empresa, 
        limit: '200', 
        last_id: '0'
      }
      await this.getProfiles(data_profiles).then(res =>{
        console.log('respuesta del store profiles', res);
      });
      console.log('lista de perfiles', this.profile_list);
    },
    openQuestionDelete(doc) {
      this.profile_to_delete = doc.id_perfil;
      this.open_question_modal = true;
      this.question_modal_msg = "¿Estas seguro de eliminar este perfil?";
    },
    cancelQuestion() {
      this.profile_to_delete = null;
      this.open_question_modal = false;
    },
    // eliminar documento
    acceptQuestion() {
      this.show_spinner = true;
      this.deleteProfile();
    },
    closeStatus() {
      this.open_modal_status = false;
    },
    openEdit(item) {
      this.open_add_publi = true;
      this.item_selected = item;
      //this.$router
        // .push({
        //   name: "library-edit",
        //   params: { id: idDoc },
        // })
        // .catch(() => {
        //   return;
        // });
    
    },
    OpenModal(doc) {
      this.prev_wall = doc;
      this.open_modal_preview = true;
    },
    //abrir opciones
    openOption(id) {
      setTimeout(() => {
        this.option = id;
      }, 10);
    },
    outOption() {
      if (this.option != "") {
        this.option = "";
      }
    },
  },
};
</script>
